.shl-chip {
  position: relative;
  font-family: Montserrat;

  .shl-chip-action-container {
    border-radius: 4px;
    overflow: hidden;
    outline: 1px solid #f1f1f1;
    padding: 9px 16px;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    -o-transition: all 0.15s;
    transition: all 0.15s;
    transition-property: outline-color;
    cursor: default;

    .shl-chip-backgound {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #f1f1f1;
      z-index: -1;
      opacity: 0.1;
      -webkit-transition: all 0.15s;
      -moz-transition: all 0.15s;
      -o-transition: all 0.15s;
      transition: all 0.15s;
      transition-property: opacity;
    }

    .shl-chip-content {
      display: flex;
      gap: 8px;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      line-height: 18px;
      color: #151515;

      .shl-chip-icon {
        color: #fe7d00;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &:active {
      .shl-chip-backgound {
        opacity: 1;
      }
    }
  }

  &:hover {
    .shl-chip-action-container {
      outline-color: #151515;
    }
  }
}

.shl-chip-closeable {
  .shl-chip-action-container {
    padding-right: 44px;
  }
}

.shl-chip-highlighted{
  .shl-chip-action-container {
    outline-color: #FFB30F;

    .shl-chip-backgound {
      background-color: #FFFCFC !important;
    }
  }
}

.shl-chip-disabled {
  pointer-events: none;

  .shl-chip-action-container {
    outline-color: #b9b9b9;

    .shl-chip-backgound {
      background-color: #f1f1f1 !important;
    }

    .shl-chip-content {
      color: #b9b9b9;

      .shl-chip-icon {
        color: #b9b9b9;
      }
    }
  }

  .shl-chip-close-btn {
    color: #b9b9b9;
  }
}

.shl-chip-loading {
  pointer-events: none;

  .shl-chip-action-container {
    outline-color: #f1f1f1;

    .shl-chip-backgound {
      background-color: #f1f1f1 !important;
    }

    .shl-chip-content {
      color: #b9b9b9;

      .shl-chip-icon {
        color: #b9b9b9;
      }
    }
  }

  .shl-chip-close-btn {
    color: #b9b9b9;
  }

  &.shl-chip-not-closeable {
    .shl-chip-action-container {
      padding-right: 39px;
    }
  }
}

.shl-chip-close-btn {
  position: absolute;
  cursor: pointer;
  right: 8px;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  box-shadow: none;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  background-color: #fffcfc;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
  transition-property: background-color;

  i {
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    -o-transition: all 0.15s;
    transition: all 0.15s;
    transition-property: transform;
  }

  &:hover {
    background-color: #f1f1f1;
  }

  &:active {
    i {
      transform: scale(0.9);
    }
  }
}

.shl-chip-loader {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
  display: flex;
  justify-content: center;
  align-items: center;

  .shl-chip-spinner {
    display: inline-block;
    position: relative;
    width: 14px;
    height: 14px;

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 13px;
      height: 13px;
      border: 1px solid #151515;
      border-radius: 50%;
      animation: shl-chip-spinner 1.2s cubic-bezier(0.5, 0.5, 0.5, 0.5) infinite;
      border-color: #151515 #151515 #151515 transparent;

      &:nth-child(1) {
        animation-delay: -0.45s;
      }
    }
  }
}

@keyframes shl-chip-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
