@use 'abstracts/abstracts' as *;
@use 'abstracts/variables' as *;
.popover {
    max-width: unset !important;
}

.form-heading {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.25;
    color: #5b5b5b;
    margin: 0px 0px 38px 0px;
    text-align: center;
}

.form-group {
    & > label {
        font-size: 1.4rem;
        font-weight: $semibold;
        color: $cadetBlue;
        margin: 0px 0px 6px 0px;
    }
}

.form-control {
    padding: 15px 22px 15px 20px;
    height: auto;
    font-size: 1.4rem;
    line-height: 1.2;
    color: #676767;

    &::placeholder {
        color: $ghost;
    }
}

.ng-select-wrapper {
    .ng-select-container {
        border-radius: 2px;
        border-color: $athensGray;
        // @include transition($input-transition);
    }

    .ng-select.ng-select-single .ng-select-container {
        height: 51px;

        .ng-value-container .ng-input {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .ng-select.ng-select-opened > .ng-select-container {
        // color: $input-focus-color;
        // background-color: $input-focus-bg;
        // border-color: $input-focus-border-color;
    }

    .ng-value {
        font-size: 1.4rem;
    }

    .ng-dropdown-panel {
        border-radius: 2px;
        // border-color: $input-focus-border-color;

        &.ng-select-top {
            border-bottom-color: $mischka;
        }

        &.ng-select-bottom {
            border-top-color: $mischka;
        }

        .ng-dropdown-panel-items .ng-option {
            font-size: 1.3rem;
            transition: background-color 0.1s;

            &.ng-option-marked {
                background-color: transparentize($perano, 0.5);
            }
        }
    }
}

label.switch {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 30px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: '';
        height: 22px;
        width: 22px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    input:checked + .slider {
        background-color: $appleBlossom;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px $appleBlossom;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(22px);
        -ms-transform: translateX(22px);
        transform: translateX(22px);
    }

    .slider.round {
        border-radius: 30px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
}

.transportation-company-form-group {
    .ng-select {
        font-size: 1.4rem;

        &.ng-select-disabled > .ng-select-container {
            background-color: #e9ecef;
        }

        .ng-select-container {
            min-height: 48px;
            height: auto;
            border-radius: 0;
            border: 1px solid #f2f3f6;
            display: flex;
            align-items: center;
        }

        .ng-dropdown-panel {
            border-color: #f2f3f6 !important;
        }

        &.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
            color: #acb3c3;
            background: #f2f3f6;
            font-weight: 600;
            font-size: 1.4rem;

            .ng-value-icon {
                &:hover {
                    background: white;
                    color: #757b88;
                }

                &.left {
                    border-color: #edeef2;
                }
            }
        }

        &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
            border-color: #da98a0;
            outline: 0;
            box-shadow: rgba(178, 64, 78, 0.25) 0 0 0 2px;
        }

        .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
            font-weight: 600;
        }

        .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
        .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked,
        .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
            color: #acb3c3;
            background: #f2f3f6;
            font-weight: 600;
        }
    }
}

.ngb-dp-day.disabled {
    opacity: 0.5;

    .custom-day:hover,
    .custom-day.range.faded {
        background: transparent !important;
        color: #6f6f6f !important;
    }
}

.new-custom-checkbox {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: $rollingStone;
    font-size: 14px;
    font-weight: normal;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: $rollingStone;
        border-radius: 2px;
    }

    &:hover input ~ .checkmark {
        background-color: $rollingStone;
    }

    input:checked ~ .checkmark {
        background-color: $nightShadz;
    }

    .checkmark {
        &:after {
            content: '';
            position: absolute;
            display: none;
        }
    }

    input:checked ~ .checkmark:after {
        display: block;
    }

    .checkmark:after {
        left: 7px;
        top: 3px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}
