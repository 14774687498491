.tooltip {
    z-index: 20000;
    position: absolute;
    display: inline-block;
    color: #fff;
    background-color: #505358;
    max-width: 200px;
    padding: 10px;
    word-wrap: break-word;
    border-radius: 4px;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.24px;

    transform: translate(-50%, 0);
    &-right {
        &::after {
            right: 100%;
            top: 50%;
            border: solid transparent;
            content: '';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: transparent;
            border-right-color: #505358;
            border-width: 5px;
            margin-top: -5px;
        }
    }
    &-bottom {
        &::after {
            right: 0;
            left: 0;
            margin: auto;
            top: -5px;
            border: solid transparent;
            content: '';
            height: 0;
            width: 0;
            transform: rotate(90deg);
            position: absolute;
            pointer-events: none;
            border-color: transparent;
            border-right-color: #505358;
            border-width: 5px;
            margin-top: -5px;
        }
    }
}
