/* total width */
.shl-scrollbar::-webkit-scrollbar {
  background-color: #f1f1f1;
  width: 10px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

/* background of the scrollbar except button or resizer */
.shl-scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  width: 4px;
}
// .shl-scrollbar::-webkit-scrollbar-track:hover {
//   background-color: #f4f4f4;
// }

/* scrollbar itself */
.shl-scrollbar::-webkit-scrollbar-thumb {
  background-color: #343434;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  border: 3px solid #f1f1f1;
  -webkit-transition: border 0.15s;
  -moz-transition: border 0.15s;
  -o-transition: border 0.15s;
  transition: border 0.15s;
}
.shl-scrollbar::-webkit-scrollbar-thumb:hover {
  border: 1px solid #f4f4f4;
}

/* set button(top and bottom of the scrollbar) */
.shl-scrollbar::-webkit-scrollbar-button {
  display: none;
}

.shl-input-asterisk {
  color: #ec0808;
}

.shl-clear-icon-btn {
  width: 24px;
  height: 24px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background-color: #f1f5f8;
  color: #a7b0b3;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.36;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;

  &:hover {
    opacity: 1;
  }
}

.shl-input-without-arrows {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
}

.shl-form-field-error {
  font-family: Montserrat;
  font-size: 10px;
  line-height: 13px;
  color: #ec0808;
  margin-top: 2px;
  display: flex;
  gap: 2px;
  align-items: center;
}
