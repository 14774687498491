.toggle-lg {
  height: 32px;

  label {
    height: 32px;

    &:active {
      .toggle-switch {
        width: 36px;
        border-radius: 28px;
      }

      input:checked + .toggle-slider {
        .toggle-switch {
          -webkit-transform: translateX(22px);
          -ms-transform: translateX(22px);
          transform: translateX(22px);
        }
      }
    }
  }

  .toggle-slider {
    border-radius: 34px;
    min-width: 58px;
    height: 28px;

    .toggle-switch {
      height: 32px;
      width: 32px;
      box-sizing: border-box;
      top: -2px;
    }

    .toggle-inside-content {
      width: calc(100% - 8px);
      margin-left: 4px;

      .toggle-inside-on-content {
        font-size: 14px;
        line-height: 14px;

        i {
          font-size: 12px;
          line-height: 12px;
        }
      }

      .toggle-inside-off-content {
        font-size: 14px;
        line-height: 14px;

        i {
          font-size: 12px;
          line-height: 12px;
        }
      }
    }
  }

  input:checked + .toggle-slider {
    .toggle-switch {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }

  .toggle-lds-ring {
    width: 32px;
    height: 32px;

    div {
      width: 24px;
      height: 24px;
      margin: 2px;
    }
  }
}

.toggle-md {
  height: 24px;

  label {
    height: 24px;

    &:active {
      .toggle-switch {
        width: 28px;
        border-radius: 20px;
      }

      input:checked + .toggle-slider {
        .toggle-switch {
          -webkit-transform: translateX(20px);
          -ms-transform: translateX(20px);
          transform: translateX(20px);
        }
      }
    }
  }

  .toggle-slider {
    border-radius: 34px;
    min-width: 48px;
    height: 20px;

    .toggle-switch {
      height: 24px;
      width: 24px;
      box-sizing: border-box;
      top: -2px;
    }

    .toggle-inside-content {
      .toggle-inside-on-content {
        font-size: 12px;
        line-height: 12px;

        i {
          font-size: 10px;
          line-height: 10px;
        }
      }

      .toggle-inside-off-content {
        font-size: 12px;
        line-height: 12px;

        i {
          font-size: 10px;
          line-height: 10px;
        }
      }
    }
  }

  input:checked + .toggle-slider {
    .toggle-switch {
      -webkit-transform: translateX(24px);
      -ms-transform: translateX(24px);
      transform: translateX(24px);
    }
  }

  .toggle-lds-ring {
    width: 24px;
    height: 24px;

    div {
      width: 16px;
      height: 16px;
      margin: 2px;
    }
  }
}

.toggle-sm {
  height: 20px;

  label {
    height: 20px;

    &:active {
      .toggle-switch {
        width: 24px;
        border-radius: 16px;
      }

      input:checked + .toggle-slider {
        .toggle-switch {
          -webkit-transform: translateX(18px);
          -ms-transform: translateX(18px);
          transform: translateX(18px);
        }
      }
    }
  }

  .toggle-slider {
    border-radius: 34px;
    min-width: 40px;
    height: 16px;

    .toggle-inside-content {
      .toggle-inside-on-content {
        font-size: 10px;
        line-height: 10px;

        i {
          font-size: 8px;
          line-height: 8px;
        }
      }

      .toggle-inside-off-content {
        font-size: 10px;
        line-height: 10px;

        i {
          font-size: 8px;
          line-height: 8px;
        }
      }
    }

    .toggle-switch {
      height: 18px;
      width: 18px;
      box-sizing: border-box;
      top: -1px;
    }
  }

  input:checked + .toggle-slider {
    .toggle-switch {
      -webkit-transform: translateX(22px);
      -ms-transform: translateX(22px);
      transform: translateX(22px);
    }
  }

  .toggle-lds-ring {
    width: 20px;
    height: 20px;

    div {
      width: 12px;
      height: 12px;
      margin: 2px;
    }
  }
}

.toggle-container {
  position: relative;
  // display: flex;
  font-family: Montserrat;

  label {
    display: flex;
    flex-direction: row;
    // gap: 8px;
    align-items: center;
    font-size: 15px;
    line-height: 24px;
    max-width: 100%;
    color: #151515;
    transition: color 0.15s;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .toggle-label-left {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 8px;
    }

    .toggle-label-right {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 8px;
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
  }

  input:checked + .toggle-slider {
    background-color: #411818;
    border-color: #411818;

    .toggle-inside-content {
      .toggle-inside-on-content {
        display: flex;
      }

      .toggle-inside-off-content {
        display: none;
      }
    }

    .toggle-switch {
      border-color: #411818;

      .toggle-lds-ring {
        div {
          border-color: #411818 transparent transparent transparent;
        }
      }
    }
  }
}

.toggle-slider {
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #a7b0b3;
  -webkit-transition: 0.15s;
  transition: 0.15s;

  .toggle-switch {
    position: absolute;
    border-radius: 50%;
    left: 0px;
    background-color: white;
    border: 2px solid #b9b9b9;
    -webkit-transition: 0.15s;
    transition: 0.15s;
  }
}

.toggle-disabled {
  pointer-events: none;

  label {
    input:checked + .toggle-slider {
      background-color: #b9b9b9;
      border-color: #b9b9b9;

      .toggle-switch {
        border-color: #b9b9b9;
      }
    }

    .toggle-switch {
      background-color: white;
      border-color: #b9b9b9;
    }

    .toggle-slider {
      background-color: #b9b9b9;
      border-color: #b9b9b9;
    }

    .toggle-label-left,
    .toggle-label-right {
      color: #b9b9b9;
    }
  }
}

.toggle-loading {
  pointer-events: none;
}

.toggle-lds-ring {
  display: inline-block;
  position: relative;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    border: 1px solid #a7b0b3;
    border-radius: 50%;
    animation: toggle-lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #a7b0b3 transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }
  }
}

@keyframes toggle-lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.toggle-inside-content {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: #fffcfc;

  .toggle-inside-on-content {
    display: none;
    align-items: center;
    justify-content: left;
    width: 100%;
    font-weight: 100;
    text-align: left;
    transition: all 0.15ms;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  .toggle-inside-off-content {
    display: flex;
    justify-content: right;
    align-items: center;
    width: 100%;
    font-weight: 100;
    text-align: right;
    transition: all 0.15ms;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
}
