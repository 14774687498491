@use 'variables' as *;

.control-errors-list {
  color: $control_error_list-color;
  font-family: $control_error_list-font-family;

  .error {
    font-size: $control_error_list-font-size;
    line-height: $control_error_list-line-height;
    display: flex;
    gap: $control_error_list-gap;
  }
}

.pointy-control-errors-list {
  font-family: $control_error_list-font-family;
  display: inline-block;
  background-color: #151515;
  border: 1px solid #ec0808;
  padding-left: 8px;
  padding-top: 4px;
  padding-right: 16px;
  padding-bottom: 4px;
  border-radius: 4px;
  position: relative;
  margin-top: 7px;

  &::after {
    content: ' ';
    position: absolute;
    bottom: 100%; /* At the top of the tooltip */
    left: 10px;
    margin-left: -5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
    border-top-width: 5px;
    border-bottom-width: 5px;
    border-left-width: 2px;
    border-right-width: 2px;
  }

  &::before {
    content: ' ';
    position: absolute;
    bottom: 100%;
    left: 9px;
    margin-left: -5px;
    border-style: solid;
    border-color: transparent transparent #ec0808 transparent;
    border-top-width: 6px;
    border-bottom-width: 6px;
    border-left-width: 3px;
    border-right-width: 3px;
    z-index: -1;
  }

  .error {
    color: #ffffff;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    gap: 4px;

    i {
      font-size: 8px;
    }
  }
}
