@use 'abstracts/abstracts' as *;
.agm-marker-popup {
    padding: 0px;
    font-size: 1.5rem;
    font-weight: $semibold;
    color: $tundora;

    .equipment-details-wrapper {
        display: flex;
    }

    .machine-link {
        width: 150px;
        height: 110px;
        flex-shrink: 0;
        margin: 0px;
        padding: 0px;
        background: center no-repeat;
        background-size: cover;
    }

    .inventory-number {
        color: $hippiePink;
    }

    .textc {
        padding: 0px 10px 10px 10px;

        strong {
            display: block;
            font-weight: inherit;
            color: $tundora;
            margin-bottom: 3px;
        }

        p {
            margin: 0px 0px 3px;
            padding: 0px;
        }

        .address {
            font-size: 1.3rem;
            font-weight: normal;
            color: #8b8b8b;
            i {
                font-size: 1rem;
            }
        }

        .child-equipments {
            font-size: 1.3rem;
            font-weight: normal;
            color: #8b8b8b;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            i {
                font-size: 1rem;
                margin-right: 5px;
            }
        }
    }

    .container-content-wrapper {
        margin-top: 7px;
    }

    .container-content-heading {
        font-size: 1.4rem;
        margin-bottom: 4px;
    }

    .container-content-list {
        list-style: none;
        padding: 0px;
        margin: 0px;

        li {
            margin-bottom: 2px;
            font-size: 1.2rem;
        }
    }
}
