@use 'abstracts/abstracts' as *;
.custom-close {
    cursor: pointer;
    color: $mercury;
    font-size: 3.2rem;
    font-weight: bold;
    border: none;
    background-color: transparent;
    // @include transition($btn-transition);
    &:hover {
        color: darken($mercury, 20%);
    }
}
