// thin 100
@font-face {
    font-family: 'Montserrat';
    src: url('./_fonts/Montserrat/Montserrat-Thin.ttf');
    font-weight: 100;
}
@font-face {
    font-family: 'Montserrat';
    src: url('./_fonts/Montserrat/Montserrat-ThinItalic.ttf');
    font-weight: 100;
    font-style: italic;
}

// extra-light 200
@font-face {
    font-family: 'Montserrat';
    src: url('./_fonts/Montserrat/Montserrat-ExtraLight.ttf');
    font-weight: 200;
}
@font-face {
    font-family: 'Montserrat';
    src: url('./_fonts/Montserrat/Montserrat-ExtraLightItalic.ttf');
    font-weight: 200;
    font-style: italic;
}

// light 300
@font-face {
    font-family: 'Montserrat';
    src: url('./_fonts/Montserrat/Montserrat-Light.ttf');
    font-weight: 300;
}
@font-face {
    font-family: 'Montserrat';
    src: url('./_fonts/Montserrat/Montserrat-LightItalic.ttf');
    font-weight: 300;
    font-style: italic;
}

// normal 400
@font-face {
    font-family: 'Montserrat';
    src: url('./_fonts/Montserrat/Montserrat-Regular.ttf');
    font-weight: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('./_fonts/Montserrat/Montserrat-Italic.ttf');
    font-weight: normal;
    font-style: italic;
}

// medium 500
@font-face {
    font-family: 'Montserrat';
    src: url('./_fonts/Montserrat/Montserrat-Medium.ttf');
    font-weight: 500;
}
@font-face {
    font-family: 'Montserrat';
    src: url('./_fonts/Montserrat/Montserrat-MediumItalic.ttf');
    font-weight: 500;
    font-style: italic;
}

// semi-bold 600
@font-face {
    font-family: 'Montserrat';
    src: url('./_fonts/Montserrat/Montserrat-SemiBold.ttf');
    font-weight: 600;
}
@font-face {
    font-family: 'Montserrat';
    src: url('./_fonts/Montserrat/Montserrat-SemiBoldItalic.ttf');
    font-weight: 600;
    font-style: italic;
}

// bold 700
@font-face {
    font-family: 'Montserrat';
    src: url('./_fonts/Montserrat/Montserrat-Bold.ttf');
    font-weight: bold;
}
@font-face {
    font-family: 'Montserrat';
    src: url('./_fonts/Montserrat/Montserrat-BoldItalic.ttf');
    font-weight: bold;
    font-style: italic;
}

// extra-bold 800
@font-face {
    font-family: 'Montserrat';
    src: url('./_fonts/Montserrat/Montserrat-ExtraBold.ttf');
    font-weight: 800;
}
@font-face {
    font-family: 'Montserrat';
    src: url('./_fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf');
    font-weight: 800;
    font-style: italic;
}

// black 900
@font-face {
    font-family: 'Montserrat';
    src: url('./_fonts/Montserrat/Montserrat-Black.ttf');
    font-weight: 900;
}
@font-face {
    font-family: 'Montserrat';
    src: url('./_fonts/Montserrat/Montserrat-BlackItalic.ttf');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'ABCFavoritExpanded';
    src: url('./_fonts/ABCFavoritExpanded/ABCFavorit-Medium.woff2')
        format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
