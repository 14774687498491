@use 'variables' as *;

.shl-drawer-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $shl-popup-backdrop-background-color;
  z-index: -1;
}
