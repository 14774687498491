$shl-icon-arrow-down: '\e900';
$shl-icon-arrow-left: '\e901';
$shl-icon-arrow-right: '\e902';
$shl-icon-arrow-up: '\e903';
$shl-icon-calendar: '\e904';
$shl-icon-check: '\e905';
$shl-icon-check-circle: '\e906';
$shl-icon-close: '\e907';
$shl-icon-cloud-upload: '\e908';
$shl-icon-doc: '\e909';
$shl-icon-eye: '\e90a';
$shl-icon-eye-closed: '\e90b';
$shl-icon-file: '\e90c';
$shl-icon-file-arrow-up: '\e90d';
$shl-icon-file-arrow-up-fill: '\e90e';
$shl-icon-gallery-big: '\e90f';
$shl-icon-image: '\e910';
$shl-icon-info-circle: '\e911';
$shl-icon-information: '\e912';
$shl-icon-jpg: '\e913';
$shl-icon-long-arrow-right: '\e914';
$shl-icon-move: '\e915';
$shl-icon-pause-cicrle: '\e916';
$shl-icon-pdf: '\e917';
$shl-icon-png: '\e918';
$shl-icon-search: '\e919';
$shl-icon-star: '\e91a';
$shl-icon-svg: '\e91b';
$shl-icon-time: '\e91c';
$shl-icon-trash-can: '\e91d';
$shl-icon-xls: '\e91e';
