@use 'abstracts/abstracts' as *;
@use 'base/base' as *;
@use 'base/typography' as *;
@use 'components/buttons' as *;
@use 'components/close' as *;
@use 'components/forms' as *;
@use 'components/general' as *;
@use 'components/agm' as *;
@use 'components/common' as *;
@use 'components/tooltip' as *;

@use '@angular/material/prebuilt-themes/indigo-pink.css' as *;

// @use '@fortawesome/fontawesome-free/scss/fontawesome';
// @use '@fortawesome/fontawesome-free/scss/regular';
// @use '@fortawesome/fontawesome-free/scss/solid';
@use '@fortawesome/fontawesome-free/css/all.min.css' as *;
@use '@ng-select/ng-select/scss/default.theme' as *;

@use 'angular-notifier/styles/core' as *;
@use 'angular-notifier/styles/themes/theme-material' as *;
@use 'angular-notifier/styles/types/type-default' as *;
@use 'angular-notifier/styles/types/type-error' as *;
@use 'angular-notifier/styles/types/type-info' as *;
@use 'angular-notifier/styles/types/type-success' as *;
@use 'angular-notifier/styles/types/type-warning' as *;

*,
*:before,
*:after {
    box-sizing: border-box;
}

html,
body,
div,
span,
object,
iframe,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
code,
em,
img,
small,
strike,
strong,
sub,
sup,
tt,
b,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
main,
canvas,
embed,
footer,
header,
nav,
section,
video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: none;
}

footer,
header,
nav,
section,
main {
    display: block;
}

body {
    line-height: 1.5;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

input {
    //-webkit-appearance: none;
    border-radius: 0;
}

a {
    text-decoration: none;
}

.notifier__notification--error {
    background-color: #949494;
}

hotline {
    z-index: 100;
}

.cursor-pointer {
    cursor: pointer;
}

.cdk-overlay-container {
    position: fixed;
    z-index: 1001 !important;
}

.pac-container {
    z-index: 99999 !important;

    &::after {
        content: none;
    }
}

select-box-control,
date-piker-control,
input-control {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-wrapper {
        padding: 0 !important;
    }

    .mat-form-field-appearance-outline {
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-form-field-suffix {
            top: -0.4em;
        }
    }
}

.ng-select {
    padding-bottom: 0 !important;
}

.card-border-effect {
    position: relative;
    box-shadow: 3px 0 0 0 #d26674;

    &-vertical-line {
        position: relative;
        box-shadow: 3px 0 0 0 #d26674;

        &::after {
            left: 0;
            content: '';
            position: absolute;
            top: 0;
            width: 3px;
            height: 0;
            background-color: #d26674;
            transition: 0.2s linear all;
        }

        &:hover {
            &::after {
                height: 100%;
            }
        }
    }

    &::after {
        right: 0;
        content: '';
        position: absolute;
        top: 0;
        height: 3px;
        width: 0;
        background-color: #d26674;
        transition: 0.2s linear all;
    }

    &::before {
        content: '';
        left: 0;
        position: absolute;
        bottom: 0;
        height: 3px;
        width: 0;
        background-color: #d26674;
        transition: 0.2s linear all;
    }

    &:hover {
        &::before,
        &::after {
            width: 100%;
        }
    }
}

.add-request {
    .pac-target-input {
        border-radius: 30px !important;
    }

    .new-category-search-input {
        height: 50px !important;
    }
}

.add-request-timepicker {
    padding-bottom: 4px;

    .ngx-timepicker {
        border-bottom: 1px solid rgb(240 240 240) !important;
        border-radius: 10px;
    }

    input {
        height: 50px;
        margin-top: 4px !important;
        margin-left: 7px !important;
    }
}

@keyframes loader {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}

.animateLoader {
    transition: all 1s linear;
    animation: loader 1s infinite;
}

.add-req-form {
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-checkbox-inner-container {
        width: 22px !important;
        height: 22px !important;
    }
}

.user-select-none {
    user-select: none;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
}

.cdk-virtual-scroll-content-wrapper {
    min-height: 60vh;
}

.default-transportantionstyle-form {
    max-width: 400px;
}

.default-transportantionstyle-map {
    width: 400px;
    flex: 0 0 400px;
}

.animated-loader-wrapper {
    width: 100%;
    height: 110px;
    -webkit-mask: linear-gradient(#000 0 0) top / 100% 70%;
    -webkit-mask-repeat: no-repeat;
    background: linear-gradient(90deg, #ccca 40%, #fffa, #ccca 60%) right/300% 100%;
    animation: m 1s linear infinite;
}

@keyframes m {
    to {
        background-position: left;
    }
}

.overdue {
    color: var(--primary_candy-apple);
}

.loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading__img {
    width: 64px;
    -webkit-animation: leaves 1s ease-in-out infinite alternate;
    animation: leaves 1s ease-in-out infinite alternate;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
}

@-webkit-keyframes leaves {
    0% {
        -webkit-transform: scale(1);
    }

    100% {
        -webkit-transform: scale(1.5);
    }
}

@keyframes leaves {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.5);
    }
}

.transportation-detail {
    .mat-mdc-dialog-container {
        background-color: var(--secondary_snow);
        padding: 0px !important;
    }
}

.highlighted {
    color: var(--primary_candy-apple);
}

.new-background {
    background-color: var(--primary_cultured);
}

.mat-mdc-dialog-container:has(> shl-confirmation) {
    padding: 0 !important;
}
