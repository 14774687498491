@use 'variables' as *;

.shl-select-show-above-overlay {
  position: relative;
  z-index: 10001;
}

.shl-select {
  font-family: $shl-select-font-family;
  position: relative;

  .shl-select-inputs-container {
    position: relative;

    .shl-select-input {
      transform: scale(0);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.shl-select-close {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 6px;
  border-radius: 50%;
  background-color: transparent;
  color: #151515;
  opacity: 1;
  cursor: pointer;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;

  &.hovered {
    background-color: #f1f5f8;
    opacity: 1;
  }

  &.active {
    opacity: 1;
    background-color: #151515;
    color: #f1f5f8;
  }

  &.disabled {
    background-color: #b9b9b9;
    color: #f1f1f1;
    opacity: 1;
  }

  &.opened {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.shl-select-value-counter {
  span {
    color: #151515;
    font-size: 18px;
    line-height: 22px;
  }

  &.disabled {
    span {
      color: #c6c6c6;
    }
  }
}

.shl-select-options-overlay {
  font-family: $shl-select-font-family;

  &.tree-options {
    transform: scale(0);
    transform-origin: top center;
    opacity: 0;
    -webkit-transition: all 0.1s;
    -moz-transition: all 0.1s;
    -o-transition: all 0.1s;
    transition: all 0.1s;
  }

  &.closed {
    transform-origin: top center;
    transform: scale(0);
    opacity: 0;
  }

  &.opened {
    transform-origin: top center;
    transform: scale(1);
    opacity: 1;
  }
}

.shl-select-options {
  background-color: #ffffff;
  border-radius: 2px;
  border: 1px solid #dbdbdb;

  .shl-select-options-footer {
    border-top: 1px solid #dbdbdb;
    padding: 10px;
  }

  .shl-select-options-container {
    padding-bottom: 20px;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;

    .shl-select-options-columns {
      display: flex;
      flex-direction: row;
      width: 100%;

      .shl-select-options-column {
        width: 50%;
      }
    }
  }
}

.shl-select-option {
  padding-left: 8px;
  padding-right: 8px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
  cursor: pointer;

  &:last-child {
    // margin-bottom: 20px;
  }

  .shl-select-option-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    width: 100%;

    .shl-select-option-img {
      img {
        width: 24px;
        height: 24px;
      }
    }

    .shl-select-option-prefix,
    .shl-select-option-suffix {
      font-size: 16px;
      line-height: 19px;
      color: #a7b0b3;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 24px;
    }

    .shl-select-option-text {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;

      .shl-select-option-text-primary {
        font-size: 16px;
        line-height: 19px;
        color: #151515cc;
        width: 100%;
        position: relative;
        text-transform: capitalize;

        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
          white-space: nowrap;
        }
        &--no-width {
          font-size: 14px;
          width: auto;
        }
      }

      .shl-select-option-text-secondary {
        font-size: 14px;
        line-height: 18px;
        color: #7a8990;
        position: relative;
        text-transform: capitalize;

        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
          white-space: nowrap;
        }
      }

      .shl-select-option-text-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }

  &.hovered {
    background-color: #fff8f6;
  }

  &.selected {
    background-color: #fff8f6;
  }

  &.disabled {
    background-color: #f3f3f3;
    pointer-events: none;
    cursor: default;

    .shl-select-option-prefix,
    .shl-select-option-suffix {
      color: #c6c6c6;
    }

    .shl-select-option-text {
      .shl-select-option-text-primary,
      .shl-select-option-text-secondary {
        color: #c6c6c6;
      }
    }

    .shl-select-option-img {
      opacity: 0.6;
    }
  }
}

.shl-select-options-empty {
  padding: 8px;

  .shl-select-options-empty-container {
    display: flex;
    gap: 8px;
  }

  div:first-child {
    span {
      width: 31px;
      height: 31px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #c6c6c6;
      position: relative;

      i:first-child {
        color: #c6c6c6;
        font-size: 31px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.6;
      }

      i:last-child {
        color: #c6c6c6;
        font-size: 6px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-6px, -6px);
      }
    }
  }

  .shl-select-option-empty-texts {
    // margin-bottom: 16px;

    p {
      color: #7a8990;
      font-size: 16px;
      line-height: 24px;
      margin: 0px;
    }
  }
}

.shl-select-chip-view {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.shl-tree-option {
  .shl-tree-option-container {
    display: flex;
    flex-direction: column;

    .shl-tree-option-parent {
      cursor: pointer;
      height: 48px;
      display: flex;
      gap: 8px;
      align-items: center;
      padding-left: 8px;
      padding-right: 8px;
      margin-bottom: 1px;
      border: 1px solid transparent;
      border-bottom-color: #f8f8f8;
      box-sizing: content-box;
      -webkit-transition: all 0.15s ease;
      -moz-transition: all 0.15s ease;
      -ms-transition: all 0.15s ease;
      -o-transition: all 0.15s ease;
      transition: all 0.15s ease;

      &.hovered {
        background-color: #fff8f6;
      }

      &.selected {
        background-color: #fff8f6;
        border-radius: 2px;
        border: 1px solid #a7b0b3;
      }

      .shl-select-option-dropdown {
        div {
          width: 24px;
        }

        button {
          cursor: pointer;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: transparent;
          border: none;
          padding: 0;
          transition: all 0.15s;

          i {
            color: #151515;
            font-size: 6px;
          }

          &:hover {
            background-color: #f1f5f8;
          }

          &:active {
            background-color: #151515;

            i {
              color: #fffcfc;
            }
          }

          &.opened {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        }
      }

      .shl-select-option-img {
        img {
          width: 24px;
          height: 24px;
        }
      }

      .shl-select-option-prefix,
      .shl-select-option-suffix {
        font-size: 16px;
        line-height: 19px;
        color: #a7b0b3;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 24px;
      }

      .shl-select-option-text {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;

        .shl-select-option-text-primary {
          font-size: 16px;
          line-height: 19px;
          color: #151515cc;
          width: 100%;
          position: relative;

          span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: block;
            white-space: nowrap;
          }
        }

        .shl-select-option-text-secondary {
          font-size: 14px;
          line-height: 18px;
          color: #7a8990;
          width: 100%;
          position: relative;

          span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: block;
            white-space: nowrap;
          }
        }
      }
    }

    .shl-tree-option-child {
      padding-left: 16px;
      background-color: #f8f8f8;
    }
  }
}
