$shl-slider-primary: #ff1900;
$shl-slider-secondary2: #343434;
$shl-slider-secondary3: #a7b0b3;
$shl-slider-black: #151515;
$shl-slider-gray: #f1f1f1;
$shl-slider-disabled: #b9b9b9;
$shl-slider-white: #fffcfc;
$shl-slider-error: #ec0808;

.shl-slider-size-md {
  .shl-slider-input {
    width: calc(100% + 30px);
    height: 30px;
    border-radius: 15px;
    margin-left: -15px;
  }

  .shl-slider-input::-webkit-slider-thumb {
    width: 30px;
    height: 30px;
    border-radius: 15px;
  }

  .shl-slider-input::-moz-range-thumb {
    width: 30px;
    height: 30px;
    border-radius: 15px;
  }

  .shl-slider-input::-ms-range-thumb {
    width: 30px;
    height: 30px;
    border-radius: 15px;
  }

  &.shl-slider-value-inside-thumb {
    .shl-slider-input {
      width: calc(100% + 50px);
      height: 30px;
      border-radius: 15px;
      margin-left: -25px;
    }

    .shl-slider-input::-webkit-slider-thumb {
      width: 50px;
      height: 30px;
      border-radius: 15px;
    }

    .shl-slider-input::-moz-range-thumb {
      width: 50px;
      height: 30px;
      border-radius: 15px;
    }

    .shl-slider-input::-ms-range-thumb {
      width: 50px;
      height: 30px;
      border-radius: 15px;
    }
  }
}

.shl-slider-size-lg {
  .shl-slider-input {
    width: calc(100% + 36px);
    height: 36px;
    margin-left: -18px;
    // margin-top: -3px;
  }

  .shl-slider-input::-webkit-slider-thumb {
    width: 36px;
    height: 36px;
  }

  .shl-slider-input::-moz-range-thumb {
    width: 36px;
    height: 36px;
  }

  .shl-slider-input::-ms-range-thumb {
    width: 36px;
    height: 36px;
  }

  &.shl-slider-value-inside-thumb {
    .shl-slider-input {
      width: calc(100% + 50px);
      height: 36px;
      border-radius: 18px;
      margin-left: -25px;
    }

    .shl-slider-input::-webkit-slider-thumb {
      width: 50px;
      height: 36px;
      border-radius: 18px;
    }

    .shl-slider-input::-moz-range-thumb {
      width: 50px;
      height: 36px;
      border-radius: 18px;
    }

    .shl-slider-input::-ms-range-thumb {
      width: 50px;
      height: 36px;
      border-radius: 18px;
    }
  }
}

.shl-slider-input {
  -webkit-appearance: none !important;
  display: block;
  width: 100%;
  height: 30px;
  border-radius: 2px;
  position: relative;
  background-color: transparent;
  margin: 0;
  z-index: 3;
}

.shl-slider-blocks-height-fixer {
  height: 15px;
}

.shl-slider-input:focus {
  outline: none;
}

.shl-slider-input::-webkit-slider-runnable-track {
  z-index: 1;
}

.shl-slider-input::-moz-range-track {
  z-index: 1;
}

.shl-slider-input::-ms-range-track {
  z-index: 1;
}

.shl-slider-input::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  width: 30px;
  height: 30px;
  background-color: transparent;
  cursor: pointer;
  // border: 1px solid;
  // transform: scale(2);
}

.shl-slider-input::-moz-range-thumb {
  -webkit-appearance: none !important;
  width: 30px;
  height: 30px;
  background-color: transparent;
  cursor: pointer;
  // border: 1px solid;
  // transform: scale(2);
}

.shl-slider-input::-ms-range-thumb {
  -webkit-appearance: none !important;
  width: 30px;
  height: 30px;
  background-color: transparent;
  cursor: pointer;
  // border: 1px solid;
  // transform: scale(2);
}

.shl-slider-value-inside-thumb-input::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  width: 50px;
  height: 30px;
  background-color: transparent;
  cursor: pointer;
  // border: 1px solid;
  // transform: scale(2);
}

.shl-slider-value-inside-thumb-input::-moz-range-thumb {
  -webkit-appearance: none !important;
  width: 50px;
  height: 30px;
  background-color: transparent;
  cursor: pointer;
  // border: 1px solid;
  // transform: scale(2);
}

.shl-slider-value-inside-thumb-input::-ms-range-thumb {
  -webkit-appearance: none !important;
  width: 50px;
  height: 30px;
  background-color: transparent;
  cursor: pointer;
  // border: 1px solid;
  // transform: scale(2);
}

.shl-slider-container {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  font-family: 'Montserrat';
  z-index: 0;

  .shl-slider-label-container {
    margin-bottom: 16px;

    label {
      font-size: 16px;
      line-height: 19px;
      color: $shl-slider-secondary2;
    }
  }

  .shl-slider-tooltip-container {
    margin-bottom: 8px;

    .shl-slider-tooltip {
      display: inline-block;
      position: relative;
      font-size: 12px;
      line-height: 15px;
      padding: 8px;
      border: 1px solid $shl-slider-secondary3;
      color: $shl-slider-secondary3;
      border-radius: 3px;
      opacity: 0;
      transition: all 0.1s;
      transition: margin-left 0.1s;

      .shl-slider-tooltip-small-arrow {
        content: ' ';
        position: absolute;
        bottom: -4px;
        left: 50%;
        transform: translate(-50%, 0);
        border-style: solid;
        border-width: 4px 4px 0 4px;
        border-color: $shl-slider-white transparent transparent transparent;
        transition: all 0.1s;
      }

      .shl-slider-tooltip-big-arrow {
        content: ' ';
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translate(-50%, 0);
        border-style: solid;
        z-index: -1;
        border-width: 5px 5px 0 5px;
        border-color: $shl-slider-secondary3 transparent transparent transparent;
        transition: all 0.1s;
      }

      &.shl-tooltip-active {
        border-color: $shl-slider-black;
        color: $shl-slider-black;

        .shl-slider-tooltip-big-arrow {
          border-color: $shl-slider-black transparent transparent transparent;
        }
      }
    }
  }

  .shl-slider-values {
    font-size: 14px;
    line-height: 16px;
    width: 100%;
    color: $shl-slider-secondary3;
    height: 16px;
    margin-top: 2px;

    .shl-slider-max-value {
      display: inline;
      float: right;
    }
  }

  .shl-slider-bars-container {
    position: relative;

    .shl-slider-fill-bar {
      width: 50%;
      height: 2px;
      background-color: $shl-slider-primary;
      position: absolute;
      pointer-events: none;
      transition: all 0.1s;
    }

    .shl-slider-input-bar {
      width: 100%;
      height: 2px;
      border-radius: 2px;
      background-color: $shl-slider-disabled;
      position: absolute;
      pointer-events: none;
    }

    .shl-slider-thumb {
      // width: 29px;
      // height: 29px;
      position: absolute;
      background: $shl-slider-white;
      // border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $shl-slider-secondary3;
      border: 1px solid $shl-slider-secondary3;
      transition: all 0.1s;
      z-index: 2;

      .shl-slider-thumb-value-inside {
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-left: 8px;
        padding-right: 8px;
        box-sizing: border-box;
      }

      &.shl-thumb-active {
        background-color: $shl-slider-gray;
        border-color: $shl-slider-black;
        color: $shl-slider-black;
      }
    }
  }

  .shl-slider-blocks {
    position: absolute;
    top: 14px;
    width: 100%;
    z-index: 1;

    .shl-slider-blocks-relative-container {
      position: relative;
      width: 100%;
      height: 34px;

      .shl-slider-block {
        transform: translate(-50%, 0);
        position: absolute;
        cursor: pointer;

        .shl-slider-block-line {
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            height: 18px;
            width: 1px;
            transition: background-color 0.1s ease-in;
          }

          &.shl-slider-block-line-active {
            span {
              background-color: $shl-slider-primary;
            }
          }

          &.shl-slider-block-line-inactive {
            span {
              background-color: $shl-slider-secondary3 !important;
            }
          }
        }

        .shl-slider-block-value {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }

  &.shl-slider-value-inside-thumb {
  }

  &.shl-slider-size-md {
    .shl-slider-bars-container {
      .shl-slider-thumb {
        width: 29px;
        height: 29px;
        border-radius: 15px;
      }

      .shl-slider-fill-bar {
        top: 14px;
      }

      .shl-slider-input-bar {
        top: 14px;
      }
    }

    .shl-slider-blocks {
      top: 14px;

      .shl-slider-blocks-relative-container {
        height: 34px;

        .shl-slider-block {
          .shl-slider-block-line {
            span {
              height: 18px;
            }
          }
        }
      }
    }

    &.shl-slider-value-inside-thumb {
      .shl-slider-bars-container {
        .shl-slider-thumb {
          width: 50px;
          height: 29px;
          border-radius: 15px;
        }
      }
    }
  }

  &.shl-slider-size-lg {
    .shl-slider-bars-container {
      .shl-slider-thumb {
        width: 35px;
        height: 35px;
        border-radius: 18px;
        // margin-top: -3px;
      }

      .shl-slider-fill-bar {
        top: 17px;
      }

      .shl-slider-input-bar {
        top: 17px;
      }
    }

    .shl-slider-blocks {
      top: 17px;

      .shl-slider-blocks-relative-container {
        height: 34px;

        .shl-slider-block {
          .shl-slider-block-line {
            span {
              height: 21px;
            }
          }
        }
      }
    }

    &.shl-slider-value-inside-thumb {
      .shl-slider-bars-container {
        .shl-slider-thumb {
          width: 50px;
          height: 35px;
          border-radius: 15px;
        }
      }
    }
  }

  &.shl-slider-invalid {
    .shl-slider-bars-container {
      .shl-slider-fill-bar {
        background-color: $shl-slider-error;
      }

      .shl-slider-blocks {
        .shl-slider-blocks-relative-container {
          .shl-slider-block {
            .shl-slider-block-line {
              &.shl-slider-block-line-active {
                span {
                  background-color: $shl-slider-error;
                }
              }
            }
          }
        }
      }
    }
  }

  &.shl-slider-disabled {
    .shl-slider-tooltip-container {
      .shl-slider-tooltip {
        border: 1px solid $shl-slider-disabled;
        color: $shl-slider-disabled;
        background-color: $shl-slider-gray;

        .shl-slider-tooltip-small-arrow {
          border-color: $shl-slider-gray transparent transparent transparent;
        }

        .shl-slider-tooltip-big-arrow {
          border-color: $shl-slider-secondary3 transparent transparent
            transparent;
        }
      }
    }

    .shl-slider-bars-container {
      .shl-slider-fill-bar {
        background-color: $shl-slider-disabled;
      }

      .shl-slider-input-bar {
        opacity: 0.54;
      }

      // .shl-slider-thumb {
      //   i {
      //     visibility: hidden;
      //   }
      // }

      .shl-slider-blocks {
        .shl-slider-blocks-relative-container {
          .shl-slider-block {
            .shl-slider-block-line {
              span {
                background-color: $shl-slider-disabled;
                opacity: 0.54;
              }
            }
          }
        }
      }
    }
  }
}
