$counter-font-family: Montserrat;
$counter-btn-background-color: #fff;
$counter-btn-border-color: #e8f0fe;
$counter-inner-btn-color: #a7b0b3;
$counter-inner-btn-border-color: #a7b0b3;
$counter-inner-btn-hovered-color: #151515;
$counter-inner-btn-hovered-border-color: #151515;
$counter-inner-btn-active-color: #f1f1f1;
$counter-inner-btn-active-border-color: #151515;
$counter-inner-btn-active-background-color: #151515;
$counter-disabled-btn-border-color: #e8f0fe;
$counter-disabled-btn-background-color: #f3f3f3;
$counter-disabled-inner-btn-border-color: #a7b0b3;
$counter-disabled-inner-btn-color: #a7b0b3;
$counter-lg-btn-width: 54px;
$counter-lg-btn-height: 54px;
$counter-md-btn-width: 46px;
$counter-md-btn-height: 46px;
$counter-sm-btn-width: 38px;
$counter-sm-btn-height: 38px;
