.shl-form-field {
  display: inline-block;
  font-family: Montserrat;

  .shl-form-field-wrapper {
    background-color: #ffffff;
    border: 1px solid transparent;
    outline: 1px solid #f1f1f1;
    border-radius: 2px;
    overflow: hidden;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    -o-transition: all 0.15s;
    transition: all 0.15s;

    .shl-form-field-flex {
      display: inline-flex;
      gap: 8px;
      padding-left: 16px;
      padding-right: 16px;
      width: 100%;
      box-sizing: border-box;

      &.has-prefix {
        padding-left: 8px;
      }

      .shl-form-field-prefix {
        display: flex;
        align-items: center;
        gap: 8px;
        line-height: 0;
        pointer-events: auto;
        color: #a7b0b3;
        font-size: 16px;
      }

      .shl-form-field-suffix {
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: center;
        line-height: 0;
        pointer-events: auto;
        color: #a7b0b3;
        font-size: 16px;
      }

      .shl-form-field-infix {
        flex: auto;
        min-width: 0;
        width: 180px;
        position: relative;
        box-sizing: border-box;
        position: relative;
        gap: 8px;

        label {
          position: absolute;
          pointer-events: none;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          color: #7a8990;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          -webkit-transition: all 0.15s;
          -moz-transition: all 0.15s;
          -o-transition: all 0.15s;
          transition: all 0.15s;

          .shl-form-field-label-asterisk {
            color: #ec0808;
          }
        }

        input {
          border: none;
          outline: none;
          width: 100%;
          min-width: 0;
          overflow: hidden;
          text-overflow: ellipsis !important;
          white-space: nowrap;
          color: #151515;
          background-color: transparent;

          &[placeholder] {
            overflow: hidden;
            text-overflow: ellipsis !important;
            white-space: nowrap;
            opacity: 0;
          }

          &::placeholder,
          &::-ms-input-placeholder,
          &:-ms-input-placeholder {
            overflow: hidden;
            text-overflow: ellipsis !important;
            white-space: nowrap;
            opacity: 0;
          }
        }

        &.floated {
          input {
            &[placeholder] {
              opacity: 1;
            }

            &::placeholder,
            &::-ms-input-placeholder,
            &:-ms-input-placeholder {
              opacity: 1;
            }
          }
        }

        &.without-label {
          input {
            &[placeholder] {
              opacity: 1;
            }

            &::placeholder,
            &::-ms-input-placeholder,
            &:-ms-input-placeholder {
              opacity: 1;
            }
          }
        }
      }
    }

    &.focus-inside {
      border-color: #151515;
      outline-color: #151515;

      .shl-form-field-flex {
        .shl-form-field-infix {
          label {
            color: #ff1900;
          }
        }
      }
    }

    &.centered {
      input {
        text-align: center;
      }

      label {
        text-align: center;
      }
    }

    &:hover {
      outline-color: #151515;
    }

    &.invalid {
      background-color: #fff8f6;
      outline-color: #ec0808;

      &.focus-inside {
        border-color: #ec0808;
      }
    }

    &.disabled {
      background-color: #f3f3f3;
      border-color: #b9b9b9;
      outline-color: #b9b9b9;

      .shl-form-field-flex {
        .shl-form-field-prefix {
          color: #c6c6c6;
        }

        .shl-form-field-suffix {
          color: #c6c6c6;
        }

        .shl-form-field-infix {
          input {
            color: #c6c6c6;
          }

          label {
            color: #c6c6c6;
          }
        }
      }
    }
  }

  &.lg {
    .shl-form-field-wrapper {
      .shl-form-field-flex {
        .shl-form-field-infix {
          label {
            font-size: 18px;
            line-height: 22px;
          }

          input {
            font-size: 16px;
            line-height: 19px;
            padding-bottom: 8px;
            padding-top: 27px;
          }

          &.floated {
            label {
              font-size: 14px;
              line-height: 18px;
              top: 18px;
            }
          }

          &.without-label {
            input {
              font-size: 16px;
              line-height: 18px;
              padding-top: 18px;
              padding-bottom: 18px;
            }
          }
        }
      }
    }
  }

  &.md {
    .shl-form-field-wrapper {
      .shl-form-field-flex {
        .shl-form-field-infix {
          label {
            font-size: 16px;
            line-height: 19px;
          }

          input {
            font-size: 14px;
            line-height: 18px;
            padding-bottom: 6px;
            padding-top: 22px;
          }

          &.floated {
            label {
              font-size: 12px;
              line-height: 15px;
              top: 14px;
            }
          }

          &.without-label {
            input {
              font-size: 14px;
              line-height: 18px;
              padding-top: 14px;
              padding-bottom: 14px;
            }
          }
        }
      }
    }
  }

  &.sm {
    .shl-form-field-wrapper {
      .shl-form-field-flex {
        .shl-form-field-infix {
          label {
            font-size: 16px;
            line-height: 19px;
          }

          input {
            font-size: 14px;
            line-height: 17px;
            padding-bottom: 1px;
            padding-top: 16px;
          }

          &.floated {
            label {
              font-size: 12px;
              line-height: 15px;
              top: 10px;
            }
          }

          &.without-label {
            input {
              font-size: 14px;
              line-height: 16px;
              padding-top: 9px;
              padding-bottom: 9px;
            }
          }
        }
      }
    }
  }
}
