.shl-accordion {
  display: block;
  //   border-radius: 2px;
  //   overflow: hidden;
  font-family: Montserrat;

  .shl-accordion-item {
    border: 1px solid;
    border-bottom-color: #a7b0b3;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;

    .shl-accordion-item-header {
      position: relative;
      border-radius: 2px;
      border: 2px solid transparent;
      margin-top: -2px;
      margin-bottom: -2px;
      margin-left: -2px;
      margin-right: -2px;

      input {
        height: 0;
        padding: 0;
        margin: 0;
        width: 0;
        border: 0;
        position: absolute;
        top: 0;
        left: 0;
      }

      .shl-accordion-item-header-content {
        padding-top: 11px;
        padding-bottom: 11px;
        padding-left: 16px;
        padding-right: 16px;
        height: 40px;
        box-sizing: border-box;
        color: #151515;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        cursor: pointer;
        -webkit-transition: all 0.15s;
        -moz-transition: all 0.15s;
        -o-transition: all 0.15s;
        transition: all 0.15s;

        .shl-accordion-item-header-prefix,
        .shl-accordion-item-header-suffix,
        .shl-accordion-item-header-text {
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            font-size: 11px;
          }
        }

        .shl-accordion-item-header-prefix {
          margin-right: 8px;
        }

        .shl-accordion-item-header-suffix {
          margin-left: 8px;
          margin-right: 8px;
        }

        .shl-accordion-item-header-text {
          justify-content: left;
          width: 100%;
        }

        .shl-accordion-item-count {
          margin-left: 8px;
          margin-right: 8px;
          padding-top: 5px;
          padding-bottom: 5px;
          padding-left: 9px;
          padding-right: 9px;
          border-radius: 2px;
          font-size: 14px;
          line-height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #151515;
          background-color: #f1f1f1;
          color: #151515;
        }

        .shl-accordion-item-header-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
          border: none;
          padding: 0;
          margin: 0;
          background-color: transparent;

          div {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            font-size: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            -webkit-transition: all 0.15s;
            -moz-transition: all 0.15s;
            -o-transition: all 0.15s;
            transition: all 0.15s;

            &:hover {
              background-color: #f1f5f8;
            }
          }

          &:focus {
            outline: none;
          }
        }
      }
    }

    .shl-accordion-item-body {
      padding-top: 4px;
      padding-left: 17px;
      padding-right: 25px;
      padding-bottom: 8px;
      font-size: 14px;
      line-height: 21px;
      background-color: white;
    }

    &:hover {
      &:not(.disabled) {
        .shl-accordion-item-header {
          .shl-accordion-item-header-content {
            background-color: #fffcfc;
          }
        }
      }
    }

    &.focused {
      .shl-accordion-item-header {
        border-color: #151515;
      }
    }

    &.active {
      .shl-accordion-item-header {
        .shl-accordion-item-header-content {
          .shl-accordion-item-header-icon {
            div {
              -webkit-transform: rotate(180deg);
              -moz-transform: rotate(180deg);
              -ms-transform: rotate(180deg);
              -o-transform: rotate(180deg);
              transform: rotate(180deg);
              background-color: #151515;
              color: white;
            }
          }
        }
      }
    }

    &.disabled {
      .shl-accordion-item-header {
        .shl-accordion-item-header-content {
          color: #b9b9b9;
          cursor: default;

          .shl-accordion-item-header-icon {
            div {
              background-color: #b9b9b9;
              color: white;
            }
          }

          .shl-accordion-item-count {
            background-color: #f1f1f1;
            border-color: #b9b9b9;
            color: #b9b9b9;
          }
        }
      }
    }
  }
}
