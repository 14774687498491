.shl-button {
  font-family: Montserrat;
  position: relative;

  .shl-button-loading-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: block;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    overflow: hidden;
  }
}

.shl-button-filled {
  border: none;
  box-shadow: none;
  background-color: transparent;
  padding: 0;
  transition: 0.15s all;

  &.shl-button-loading {
    pointer-events: none;

    .shl-button-content-container {
      opacity: 0.54;
    }
  }

  &.shl-button-disabled {
    &.shl-button-shakeable {
      &:hover {
        animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
        perspective: 1000px;
      }
    }

    &.shl-button-not-shakeable {
      pointer-events: none;

      .shl-button-content-container {
        border-color: #b9b9b9;
        -webkit-box-shadow: inset 0px 0px 0px 1px #b9b9b9 !important;
        -moz-box-shadow: inset 0px 0px 0px 1px #b9b9b9 !important;
        box-shadow: inset 0px 0px 0px 1px #b9b9b9 !important;

        .shl-button-content-container-value {
          background-color: #b9b9b9;
          color: white;
        }
      }
    }
  }

  &.shl-button-stretched {
    width: 100%;

    .shl-button-content-container {
      .shl-button-content-container-value {
        width: 100%;
      }
    }

    &:active,
    &:focus {
      .shl-button-content-container {
        .shl-button-content-container-value {
          width: calc(100% - 8px);
        }
      }
    }
  }

  &.shl-button-itarable {
    &:hover {
      opacity: 0.74;
    }

    &:active,
    &:focus {
      outline: none;

      .shl-button-lg {
        .shl-button-content-container-value {
          border-radius: 2px;
          padding-left: 36px;
          padding-right: 36px;
          padding-top: 13px;
          padding-bottom: 13px;
          margin-left: 4px;
          margin-right: 4px;
        }
      }

      .shl-button-md {
        .shl-button-content-container-value {
          border-radius: 2px;
          padding-left: 22px;
          padding-right: 22px;
          padding-top: 9px;
          padding-bottom: 9px;
          margin-left: 4px;
          margin-right: 4px;
        }
      }

      .shl-button-sm {
        .shl-button-content-container-value {
          border-radius: 2px;
          padding-left: 14px;
          padding-right: 14px;
          padding-top: 5px;
          padding-bottom: 5px;
          margin-left: 4px;
          margin-right: 4px;
        }
      }
    }

    &:active:hover,
    &:focus:hover {
      .shl-button-content-container {
        -webkit-box-shadow: inset 0px 0px 0px 1px transparent !important;
        -moz-box-shadow: inset 0px 0px 0px 1px transparent !important;
        box-shadow: inset 0px 0px 0px 1px transparent !important;
      }
    }
  }

  .shl-button-primary {
    border: 1px solid #151515;
    -webkit-box-shadow: inset 0px 0px 0px 1px #151515;
    -moz-box-shadow: inset 0px 0px 0px 1px #151515;
    box-shadow: inset 0px 0px 0px 1px #151515;

    .shl-button-content-container-value {
      background-color: #151515;
    }
  }

  .shl-button-secondary {
    border: 1px solid #fe7d00;
    -webkit-box-shadow: inset 0px 0px 0px 1px #fe7d00;
    -moz-box-shadow: inset 0px 0px 0px 1px #fe7d00;
    box-shadow: inset 0px 0px 0px 1px #fe7d00;

    .shl-button-content-container-value {
      background-color: #fe7d00;
    }
  }

  .shl-button-content-container {
    border-radius: 2px;
    transition: 0.15s all;
    box-sizing: border-box;
    display: flex;
    // justify-content: center;
    align-items: center;

    .shl-button-content-container-value {
      box-sizing: border-box;
      display: flex;
      gap: 4px;
      align-items: center;
      flex-flow: row nowrap;
      border-radius: 0px;
      color: #f1f1f1;
      margin: 0px;
      transition: 0.15s all;
      transition: 0 margin;
      transition: 0 width;

      &::after,
      &::before {
        content: '';
        margin: auto;
      }

      .shl-button-left-icon-container,
      .shl-button-right-icon-container {
        display: flex;
        // flex: 1;
      }

      .shl-button-text {
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .shl-button-lg {
    height: 56px;

    .shl-button-content-container-value {
      font-size: 16px;
      line-height: 20px;
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 17px;
      padding-bottom: 17px;

      // .shl-button-left-icon-container,
      // .shl-button-right-icon-container {
      //   font-size: 14px;
      //   line-height: 20px;
      // }
    }
  }

  .shl-button-md {
    height: 48px;

    .shl-button-content-container-value {
      font-size: 14px;
      line-height: 20px;
      padding-left: 26px;
      padding-right: 26px;
      padding-top: 13px;
      padding-bottom: 13px;

      // .shl-button-left-icon-container,
      // .shl-button-right-icon-container {
      //   font-size: 12px;
      //   line-height: 20px;
      // }
    }
  }

  .shl-button-sm {
    height: 36px;

    .shl-button-content-container-value {
      font-size: 14px;
      line-height: 20px;
      padding-left: 18px;
      padding-right: 18px;
      padding-top: 7px;
      padding-bottom: 7px;

      // .shl-button-left-icon-container,
      // .shl-button-right-icon-container {
      //   font-size: 12px;
      //   line-height: 20px;
      // }
    }
  }
}

.shl-button-outlined {
  border: none;
  box-shadow: none;
  background-color: transparent;
  padding: 0;
  transition: 0.15s all;

  &.shl-button-loading {
    pointer-events: none;

    .shl-button-content-container {
      background-color: #a7b0b3;
      border-color: #a7b0b3;
      opacity: 0.54;
    }
  }

  &.shl-button-disabled {
    &.shl-button-shakeable {
      &:hover {
        animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
        perspective: 1000px;
      }
    }

    &.shl-button-not-shakeable {
      pointer-events: none;

      .shl-button-content-container {
        border-color: #b9b9b9;

        .shl-button-content-container-value {
          color: #b9b9b9;
        }
      }
    }
  }

  &.shl-button-stretched {
    width: 100%;

    .shl-button-content-container {
      .shl-button-content-container-value {
        width: 100%;
      }
    }

    &:active,
    &:focus {
      .shl-button-content-container {
        .shl-button-content-container-value {
          width: calc(100% - 8px);
        }
      }
    }
  }

  &.shl-button-itarable {
    &:hover {
      .shl-button-content-container {
        .shl-button-content-container-value {
          background-color: #f1f1f1;
        }
      }
    }

    &:active,
    &:focus {
      outline: none;

      .shl-button-content-container {
        .shl-button-content-container-value {
          border-color: inherit;
        }
      }

      .shl-button-lg {
        .shl-button-content-container-value {
          border-radius: 2px;
          padding-left: 35px;
          padding-right: 35px;
          padding-top: 12px;
          padding-bottom: 12px;
          margin-left: 4px;
          margin-right: 4px;
        }
      }

      .shl-button-md {
        .shl-button-content-container-value {
          border-radius: 2px;
          padding-left: 21px;
          padding-right: 21px;
          padding-top: 8px;
          padding-bottom: 8px;
          margin-left: 4px;
          margin-right: 4px;
        }
      }

      .shl-button-sm {
        .shl-button-content-container-value {
          border-radius: 2px;
          padding-left: 13px;
          padding-right: 13px;
          padding-top: 3px;
          padding-bottom: 3px;
          margin-left: 4px;
          margin-right: 4px;
        }
      }
    }

    // &:focus:hover {
    //   .shl-button-content-container {
    //   }
    // }
  }

  .shl-button-content-container {
    border: 1px solid;
    border-radius: 2px;
    transition: 0.15s all;
    box-sizing: border-box;
    display: flex;
    // justify-content: center;
    align-items: center;
    overflow: hidden;

    .shl-button-content-container-value {
      box-sizing: border-box;
      border: 1px solid transparent;
      display: flex;
      gap: 4px;
      align-items: center;
      flex-flow: row nowrap;
      border-radius: 0px;
      margin: 0px;
      transition: 0.15s all;
      transition: 0 margin;
      transition: 0 width;

      &::after,
      &::before {
        content: '';
        margin: auto;
      }

      .shl-button-left-icon-container,
      .shl-button-right-icon-container {
        display: flex;
        // flex: 1;
      }

      .shl-button-text {
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .shl-button-primary {
    border-color: #151515;

    .shl-button-content-container-value {
      background-color: transparent;
      color: #151515;
    }
  }

  .shl-button-secondary {
    border-color: #fe7d00;

    .shl-button-content-container-value {
      background-color: transparent;
      color: #fe7d00;
    }
  }

  .shl-button-lg {
    height: 56px;

    .shl-button-content-container-value {
      font-size: 16px;
      line-height: 20px;
      padding-left: 39px;
      padding-right: 39px;
      padding-top: 17px;
      padding-bottom: 17px;

      // .shl-button-left-icon-container,
      // .shl-button-right-icon-container {
      //   font-size: 14px;
      //   line-height: 20px;
      // }
    }
  }

  .shl-button-md {
    height: 48px;

    .shl-button-content-container-value {
      font-size: 14px;
      line-height: 20px;
      padding-left: 25px;
      padding-right: 25px;
      padding-top: 13px;
      padding-bottom: 13px;

      // .shl-button-left-icon-container,
      // .shl-button-right-icon-container {
      //   font-size: 12px;
      //   line-height: 20px;
      // }
    }
  }

  .shl-button-sm {
    height: 36px;

    .shl-button-content-container-value {
      font-size: 14px;
      line-height: 20px;
      padding-left: 17px;
      padding-right: 17px;
      padding-top: 7px;
      padding-bottom: 7px;

      // .shl-button-left-icon-container,
      // .shl-button-right-icon-container {
      //   font-size: 12px;
      //   line-height: 20px;
      // }
    }
  }
}

.shl-button-text {
  border: none;
  box-shadow: none;
  background-color: transparent;
  padding: 0;
  transition: 0.15s all;

  &.shl-button-loading {
    pointer-events: none;

    .shl-button-content-container {
      background-color: #a7b0b3;
      color: #151515;
      opacity: 0.54;
    }
  }

  &.shl-button-disabled {
    &.shl-button-shakeable {
      &:hover {
        animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
        perspective: 1000px;
      }
    }

    &.shl-button-not-shakeable {
      pointer-events: none;

      .shl-button-content-container-value {
        color: #b9b9b9;
      }
    }
  }

  &.shl-button-stretched {
    width: 100%;

    .shl-button-content-container {
      .shl-button-content-container-value {
        width: 100%;
      }
    }
  }

  &.shl-button-itarable {
    &:hover {
      .shl-button-content-container {
        .shl-button-content-container-value {
          background-color: #f1f1f1;
          // text-decoration: underline;
          .shl-text-button-underline {
            div {
              border-color: inherit;
            }
          }
        }
      }
    }

    &:active,
    &:focus {
      outline: none;
    }

    &:active,
    &:focus {
      .shl-button-content-container:hover {
        .shl-button-content-container-value {
          background-color: #f1f1f1;
          .shl-text-button-underline {
            div {
              border-color: transparent;
            }
          }
        }
      }
    }
  }

  .shl-button-content-container {
    transition: 0.15s all;
    box-sizing: border-box;
    display: flex;
    // justify-content: center;
    align-items: center;
    overflow: hidden;

    .shl-button-content-container-value {
      border-radius: 2px;
      box-sizing: border-box;
      display: flex;
      gap: 4px;
      align-items: center;
      flex-flow: row nowrap;
      margin: 0px;
      transition: 0.15s all;
      transition: 0 margin;
      transition: 0 width;

      &::after,
      &::before {
        content: '';
        margin: auto;
      }

      .shl-button-left-icon-container,
      .shl-button-right-icon-container {
        display: flex;
        // flex: 1;
      }

      .shl-button-text {
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .shl-text-button-underline {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        padding: inherit;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        // z-index: -1;

        div {
          height: calc(100% - 2px);
          width: calc(100% - 8px);
          box-sizing: border-box;
          border-bottom: 1px solid transparent;
          margin-bottom: 0px;
          margin-left: 4px;
          margin-right: 4px;
          // transition: border-color 0.15s;
        }
      }
    }
  }

  .shl-button-primary {
    color: #151515;
  }

  .shl-button-secondary {
    color: #fe7d00;
  }

  .shl-button-lg {
    height: 56px;

    .shl-button-content-container-value {
      font-size: 16px;
      line-height: 20px;
      padding-left: 32px;
      padding-right: 32px;
      padding-top: 16px;
      padding-bottom: 16px;

      // .shl-button-left-icon-container,
      // .shl-button-right-icon-container {
      //   font-size: 14px;
      //   line-height: 20px;
      // }
    }
  }

  .shl-button-md {
    height: 48px;

    .shl-button-content-container-value {
      font-size: 14px;
      line-height: 20px;
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 14px;
      padding-bottom: 14px;

      // .shl-button-left-icon-container,
      // .shl-button-right-icon-container {
      //   font-size: 12px;
      //   line-height: 20px;
      // }
    }
  }

  .shl-button-sm {
    height: 36px;

    .shl-button-content-container-value {
      font-size: 14px;
      line-height: 20px;
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 8px;
      padding-bottom: 8px;

      // .shl-button-left-icon-container,
      // .shl-button-right-icon-container {
      //   font-size: 12px;
      //   line-height: 20px;
      // }
    }
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-2px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(2px, 0, 0);
  }
}

.shl-icon-button {
  width: 28px;
  height: 28px;
  border: none;
  outline: none;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  color: #151515;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;

  i {
    font-size: 18px;
  }

  .shl-icon-button-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .lds-ring {
      display: inline-block;
      position: relative;
      width: 14px;
      height: 14px;
    }

    .lds-ring div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 14px;
      height: 14px;
      border: 2px solid #b9b9b9;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #b9b9b9 transparent transparent transparent;
      box-sizing: border-box;
    }

    .lds-ring div:nth-child(1) {
      animation-delay: -0.45s;
    }

    .lds-ring div:nth-child(2) {
      animation-delay: -0.3s;
    }

    .lds-ring div:nth-child(3) {
      animation-delay: -0.15s;
    }

    @keyframes lds-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  &:hover {
    background-color: #f1f1f1;
  }

  &:active {
    background-color: #ffffff;
  }

  &.disabled {
    background-color: #fffcfc;
    color: #b9b9b9;
    pointer-events: none;
  }
}

.shl-button-dir {
  font-family: Montserrat;
  border: none;
  box-shadow: none;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
  cursor: pointer;

  .shl-button-dir-loading-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: block;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    overflow: hidden;
  }

  &.lg {
    padding-left: 42px;
    padding-right: 42px;
    padding-top: 18px;
    padding-bottom: 18px;
    font-size: 16px;
    line-height: 20px;
    gap: 8px;
  }

  &.md {
    padding-left: 28px;
    padding-right: 28px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 14px;
    line-height: 18px;
    gap: 8px;
  }

  &.sm {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 9px;
    padding-bottom: 9px;
    font-size: 14px;
    line-height: 18px;
    gap: 8px;
  }

  &.filled {
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f1f1f1;

    &.primary {
      background-color: #151515;
    }

    &.secondary {
      background-color: #fe7d00;
    }

    &.loading {
      background-color: #a7b0b3;
      pointer-events: none;
    }

    &:hover {
      opacity: 0.74;
    }

    &:disabled {
      pointer-events: none;
      background-color: #b9b9b9;
      outline: none;
      border: none;
    }

    &:active,
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      -webkit-box-shadow: 0px 0px 4px 0px #15151590;
      box-shadow: 0px 0px 4px 0px #15151590;
    }
  }

  &.outlined {
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    background-color: transparent;

    &.primary {
      border-color: #151515;
      color: #151515;
    }

    &.secondary {
      border-color: #fe7d00;
      color: #fe7d00;
    }

    &.loading {
      background-color: #a7b0b3;
      pointer-events: none;
    }

    &:hover {
      background-color: #f1f1f1;
    }

    &:disabled {
      pointer-events: none;
      background-color: #b9b9b9;
      outline: none;
      border: none;
    }

    &:active,
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      -webkit-box-shadow: 0px 0px 4px 0px #15151590;
      box-shadow: 0px 0px 4px 0px #15151590;
    }
  }

  &.text {
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    &.primary {
      color: #151515;
    }

    &.secondary {
      color: #fe7d00;
    }

    &.loading {
      background-color: #a7b0b3;
      pointer-events: none;
    }

    &:hover {
      background-color: #f1f1f1;
    }

    &:disabled {
      pointer-events: none;
      background-color: #b9b9b9;
      outline: none;
      border: none;
    }

    &:active,
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      -webkit-box-shadow: 0px 0px 4px 0px #15151590;
      box-shadow: 0px 0px 4px 0px #15151590;
    }
  }
}
