$rootFontSize: 10px;
$headerHeight: 72px;
$sidebarWidth: 325px;
$pageBorderRadius: 5px;
$projectFormPaddingX: 53px;
$mapInputPaddingX: 5rem;
$mapInputMarkerSize: 1.5rem;
$pageMarginLeft: 109px;
$sidebarCollapsedWidth: 50px;

$mapInputBoxShadow: 0 0 12px 0 rgba(0, 0, 0, 0.07);
$semibold: bold;

$wildSand: #f4f4f4;
$gray1: #8b8b8b;
$silver: #b8b8b8;
$athensGray: #f2f3f6;
$appleBlossom: #b2404e;
$perano: #9ec4ef;
$ghost: #cdd1d9;
$porcelain: #f6f8f9;
$tundora: #484848;
$tundora2: #424242;
$mercury: #e4e4e4;
$alto: #cecece;
$newYorkPink: #d36c79;
$alabaster: #fafafa;
$gallery: #f0f0f0;
$scorpion: #606060;
$aliceBlue: #f5faff;
$mineShaft: #303030;
$athensGray2: #edeef2;
$mischka: #d6d8de;
$mischka2: #d6d6da;
$hippiePink: #af4150;
$athensGrayFeed: #f4f5f8;
$outerSpace: #343a40;
$paleSky: #6d757d;
$solitude: #e7f2ff;
$mariner: #2d74c9;
$nightShadz: #ba3c4d;
$cadetBlueFeed: #acb3c5;
$mariner2: #2772cc;
$iron: #dbdcde;
$woodsmoke: #16181b;
$floatingBtnBg: #b0404e;

// $pigPink:                   #fdced4;
// $pippin:                    #ffe1e4;
// $stiletto:                  #9e3946;
$appleBlossom: #b1404e;
// $mineShaftDark:             #242424;
// $gullGray:                  #99abb3;
$havelockBlue: #4a90e2;
// $geyser:                    #cfd8dc;
// $scorpion:                  #575757;
// $loblolly:                  #c2cace;
// $mercury:                   #e3e3e3;
// $concrete:                  #f2f2f2;
// $whiteLilac:                #f8f9fd;
$cadetBlue: #acb3c3;
// $athensGray2:               #e9ebf0;

$whiteSmoke: #f9f9f9;
$limedSpruce: #37474f;
$scorpionDark: #5b5b5b;
$chestnutRose: #d26674;
$altoLight: #dcdcdc;
$seashell: #f1f1f1;
$nevada: #676e7a;
$manatee: #959ba9;
$osloGray: #828893;
$redRibbon: #e00d47;
$pacificBlue: #00a2ba;
$puce: #cf828c;
$rollingStone: #7e8589;
$doveGray: #707070;

$borderPrimaryColor: #e8e9eb;
