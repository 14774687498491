$textarea-font-family: Montserrat;
$textarea-charachter-count-font-size: 10px;
$textarea-charachter-count-line-height: 13px;
$textarea-charachter-count-color: #a7b0b3;
$textarea-charachter-count-color-invalid: #ec0808;
$textarea-border-radius: 2px;
$textarea-border-color: #e8f0fe;
$textarea-clear-background-color: #f1f5f8;
$textarea-clear-color: #092532;
$textarea-label-color: #151515;
$textarea-label-floated-color: #717171;
$textarea-label-focused-color: #ff1900;
$textarea-border-hover-color: #151515;
$textarea-color: #151515;
$textarea-invalid-border-color: #ec0808;
$textarea-invalid-background-color: #fff8f6;
$textarea-disabled-border-color: #5b75815c;
$textarea-disabled-textarea-color: #c6c6c6;
$textarea-disabled-label-color: #c6c6c6;
$textarea-disabled-label-floated-color: #c6c6c6;
$textarea-lg-font-size: 14px;
$textarea-lg-font-line-height: 18px;
$textarea-lg-label-floated-size: 12px;
$textarea-lg-label-floated-line-height: 15px;
$textarea-lg-focused-label-size: 12px;
$textarea-lg-focused-label-line-height: 15px;
$textarea-lg-font-size: 14px;
$textarea-lg-line-height: 18px;
$textarea-md-font-size: 14px;
$textarea-md-font-line-height: 18px;
$textarea-md-label-floated-size: 12px;
$textarea-md-label-floated-line-height: 15px;
$textarea-md-focused-label-size: 12px;
$textarea-md-focused-label-line-height: 15px;
$textarea-md-font-size: 14px;
$textarea-md-line-height: 18px;
$textarea-sm-font-size: 14px;
$textarea-sm-font-line-height: 18px;
$textarea-sm-label-floated-size: 12px;
$textarea-sm-label-floated-line-height: 15px;
$textarea-sm-focused-label-size: 12px;
$textarea-sm-focused-label-line-height: 15px;
$textarea-sm-font-size: 14px;
$textarea-sm-line-height: 18px;
