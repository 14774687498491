@use 'variables' as *;

.textarea {
  width: 100%;
  position: relative;

  .text-area-charachter-count {
    font-size: $textarea-charachter-count-font-size;
    text-align: right;
    line-height: $textarea-charachter-count-line-height;
    font-family: $textarea-font-family;
    margin-top: 2px;
    color: $textarea-charachter-count-color;
  }

  .text-area-charachter-count-invalid {
    color: $textarea-charachter-count-color-invalid;
  }
}

.textarea-content-container {
  background-color: white;
  width: 100%;
  font-family: $textarea-font-family;
  border-radius: $textarea-border-radius;
  border: 1px solid $textarea-border-color;
  padding: 1px;
  box-sizing: border-box;
  -webkit-transition: border-color 0.2s ease;
  -moz-transition: border-color 0.2s ease;
  -o-transition: border-color 0.2s ease;
  transition: border-color 0.2s ease;

  .textarea-label-container {
    position: absolute;
    display: flex;
    justify-content: space-between;
    left: 9px;
    width: calc(100% - 18px);
    gap: 4px;

    .clear {
      display: none;
      opacity: 0;
      width: 17px;
      height: 17px;
      font-size: 8px;
      line-height: 8px;
      border-radius: 50%;
      background-color: $textarea-clear-background-color;
      color: $textarea-clear-color;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      -webkit-transition: all 0.2s ease;
      -moz-transition: all 0.2s ease;
      -ms-transition: all 0.2s ease;
      -o-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }

    label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $textarea-label-color#151515;
      -webkit-transition: all 0.2s ease;
      -moz-transition: all 0.2s ease;
      -o-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }

    .label-floated {
      color: $textarea-label-floated-color;
    }
  }

  &:focus-within {
    border: 2px solid #151515;
    padding: 0px;

    .textarea-label-container {
      label {
        color: $textarea-label-focused-color;
      }
    }
  }

  &:hover {
    border-color: $textarea-border-hover-color;

    .clear {
      display: flex;
      opacity: 0.36;

      &:hover {
        opacity: 1;
      }
    }
  }

  textarea {
    resize: vertical;
    border: none;
    outline: none;
    font-family: inherit;
    background: transparent;
    color: $textarea-color;
    width: 100%;
    margin-bottom: 8px;
    margin-left: 8px;
    margin-right: 8px;
    padding-right: 8px;
    padding: 0px;
    -webkit-padding-end: 16px;
    width: calc(100% - 32px);
    margin-bottom: 6px;
  }
}

.textarea-lg {
  .textarea-label-container {
    top: 8px;

    label {
      font-size: $textarea-lg-font-size;
      line-height: $textarea-lg-font-line-height;
    }

    .label-floated {
      font-size: $textarea-lg-label-floated-size;
      line-height: $textarea-lg-label-floated-line-height;
    }
  }

  &:focus-within {
    .textarea-label-container {
      label {
        font-size: $textarea-lg-focused-label-size;
        line-height: $textarea-lg-focused-label-line-height;
      }
    }
  }

  textarea {
    margin-top: 24px;
    font-size: $textarea-lg-font-size;
    line-height: $textarea-lg-line-height;
  }
}

.textarea-md {
  .textarea-label-container {
    top: 8px;

    label {
      font-size: $textarea-md-font-size;
      line-height: $textarea-md-font-line-height;
    }

    .label-floated {
      font-size: $textarea-md-label-floated-size;
      line-height: $textarea-md-label-floated-line-height;
    }
  }

  &:focus-within {
    .textarea-label-container {
      label {
        font-size: $textarea-md-focused-label-size;
        line-height: $textarea-md-focused-label-line-height;
      }
    }
  }

  textarea {
    margin-top: 24px;
    font-size: $textarea-md-font-size;
    line-height: $textarea-md-line-height;
  }
}

.textarea-sm {
  .textarea-label-container {
    top: 4px;

    label {
      font-size: $textarea-md-font-size;
      line-height: $textarea-md-font-line-height;
    }

    .label-floated {
      font-size: $textarea-md-label-floated-size;
      line-height: $textarea-md-label-floated-line-height;
    }
  }

  &:focus-within {
    .textarea-label-container {
      label {
        font-size: $textarea-md-focused-label-size;
        line-height: $textarea-md-focused-label-line-height;
      }
    }
  }

  textarea {
    margin-top: 19px;
    font-size: $textarea-md-font-size;
    line-height: $textarea-md-line-height;
  }
}

.textarea-invalid {
  border-color: $textarea-invalid-border-color !important;
  background-color: $textarea-invalid-background-color;

  // &:hover {
  //   border-color: #ec0808;
  // }

  // &:focus-within {
  //   border-color: #ec0808;
  // }
}

.textarea-disabled {
  pointer-events: none;
  border: 2px solid $textarea-disabled-border-color !important;
  padding: 0px;
  background-color: #ffffff;

  textarea {
    background: transparent;
    color: $textarea-disabled-textarea-color;
  }
  .textarea-label-container {
    label {
      color: $textarea-disabled-label-color;
    }

    .label-floated {
      color: $textarea-disabled-label-floated-color;
    }
  }
}
