.shl-message {
  font-family: Montserrat;

  .shl-message-thick {
    .shl-message-thick-container {
      padding-top: 8px;
      padding-bottom: 16px;
      padding-left: 16px;
      padding-right: 16px;
      background-color: #f1f1f1;
      box-shadow: 4px 4px 40px #15151514;
      border-left: 3px solid #151515;
      position: relative;
      -webkit-transition: all 0.15s;
      -moz-transition: all 0.15s;
      -o-transition: all 0.15s;
      transition: all 0.15s;

      .shl-message-thick-close-btn {
        position: absolute;
        top: 16px;
        right: 16px;
        border: none;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &:focus {
          outline: none;
        }

        i {
          font-size: 10px;
        }
      }

      .shl-message-thick-icon {
        position: absolute;
        bottom: 12px;
        right: 16px;
        opacity: 0.2;
        font-size: 51px;
      }

      .shl-message-texts {
        letter-spacing: 0.16px;
        margin-right: 82px;

        .shl-message-title {
          font-size: 14px;
          line-height: 18px;
          margin: 0;
          font-weight: 400;
        }

        .shl-message-body {
          margin: 0;
          margin-top: 8px;
          font-size: 14px;
          line-height: 21px;
        }

        .shl-message-footer {
          margin: 0;
          margin-top: 16px;
          font-size: 14px;
          line-height: 18px;
        }
      }

      &.hovered {
        background-color: #fffcfc;
      }
    }
  }

  .shl-message-line {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 16px;
    padding-right: 21px;
    background-color: #fffcfc;
    box-shadow: 4px 4px 40px #15151514;
    border-left: 3px solid #151515;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    -o-transition: all 0.15s;
    transition: all 0.15s;

    .shl-message-line-container {
      display: flex;
      align-items: center;
      gap: 16px;

      .shl-message-icon-container {
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          font-size: 18px;
        }
      }

      .shl-message-texts {
        width: 100%;
        letter-spacing: 0.16px;
        display: flex;
        gap: 8px;
        justify-content: left;
        align-items: center;

        .shl-message-title {
          font-size: 14px;
          line-height: 18px;
          font-weight: 400;
          -webkit-transition: all 0.15s;
          -moz-transition: all 0.15s;
          -o-transition: all 0.15s;
          transition: all 0.15s;
          margin: 0;
        }

        .shl-message-sub-title {
          font-size: 14px;
          line-height: 18px;
          -webkit-transition: all 0.15s;
          -moz-transition: all 0.15s;
          -o-transition: all 0.15s;
          transition: all 0.15s;
          margin: 0;
        }
      }

      .shl-message-close-container {
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          border: none;
          background-color: transparent;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          &:focus {
            outline: none;
          }

          i {
            font-size: 10px;
          }
        }
      }
    }

    &.hovered {
      background-color: #f1f1f1;

      .shl-message-line-container {
        .shl-message-texts {
          .shl-message-title,
          .shl-message-sub-title {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
