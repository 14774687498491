@use 'variables' as *;

.shl-popup-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $shl-popup-backdrop-background-color;
  z-index: -1;
}

.shl-popup-container {
  font-family: 'Montserrat', sans-serif;
  border-radius: 2px;
  border: 1px solid #f1f1f1;
  padding: 4px;
  background-color: transparent;
  position: relative;

  .shl-popup-close {
    position: absolute;
    z-index: 1;
    right: 16px;
    top: 16px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    cursor: pointer;
    transition: all 0.1s;

    &:hover {
      background-color: #f1f1f1;
    }
  }

  .shl-popup-container-content {
    border-radius: 2px;
    background-color: #fffcfc;
    padding: 16px;
    position: relative;
  }
}

.shl-confirmation-container {
  width: 546px;
  box-sizing: border-box;

  .shl-confirmation-header {
    padding-top: 7px;
    padding-bottom: 23px;
    border-bottom: 1px solid #f1f1f1;
    margin-left: -16px;
    margin-right: -16px;
    color: #151515;

    h2 {
      margin: 0px;
      font-size: 18px;
      line-height: 20px;
      font-weight: 600;
      text-align: center;
    }
  }

  .shl-confirmation-body {
    color: #151515;
    border-bottom: 1px solid #a7b0b3;
    padding-bottom: 37px;
    padding-top: 42px;
    margin-left: -16px;
    margin-right: -16px;
    text-align: center;
    font-size: 18px;
    line-height: 20px;
  }

  .shl-confirmation-footer {
    padding-top: 18px;
    padding-bottom: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
