.shl-checkbox {
  font-family: Montserrat;
  position: relative;
  display: flex;
  flex-direction: column;
}

.checkbox-lg {
  .shl-checkbox-label {
    padding-left: 24px;
    font-size: 15px;
    line-height: 24px;
    min-width: 1px;
    min-height: 24px;
  }

  .shl-checkbox-box:after {
    width: 24px;
    height: 24px;
    box-sizing: border-box;
  }

  .shl-checkbox-input:checked + .shl-checkbox-box:before {
    left: 7px;
    top: 3px;
    width: 9px;
    height: 12px;
    box-sizing: border-box;
  }
}

.checkbox-md {
  .shl-checkbox-label {
    padding-left: 20px;
    font-size: 15px;
    line-height: 20px;
    min-width: 1px;
    min-height: 20px;
  }

  .shl-checkbox-box:after {
    width: 20px;
    height: 20px;
    box-sizing: border-box;
  }

  .shl-checkbox-input:checked + .shl-checkbox-box:before {
    left: 6px;
    top: 3px;
    width: 7px;
    height: 10px;
    box-sizing: border-box;
  }
}

.shl-checkbox-label {
  max-width: 100%;
  color: #151515;
  transition: color 0.15s;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.shl-checkbox-input {
  display: none;
}

.shl-checkbox-box:before,
.shl-checkbox-box:after {
  transition: all 0.15s;
}

.shl-checkbox-box:after {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  content: ' ';
  border: 2px solid #151515;
  border-radius: 2px;
}

.shl-checkbox-label-content {
  word-break: break-all;
  padding-left: 8px;
}

.shl-checkbox-input:checked + .shl-checkbox-box:after {
  background-color: #151515;
  border-color: #151515;
}

.shl-checkbox-input:checked + .shl-checkbox-box:before {
  transform: rotate(45deg);
  position: absolute;
  border-width: 2px;
  border-style: solid;
  border-top: 0;
  border-left: 0;
  border-color: white;
  content: '';
  z-index: 1;
}

.shl-checkbox-label:hover {
  color: #5f5f5f;

  .shl-checkbox-input:checked + .shl-checkbox-box:after {
    background-color: #5f5f5f;
    border-color: #5f5f5f;
  }
}

.shl-checkbox-label:hover .shl-checkbox-box:after {
  border-color: #5f5f5f;
}

.checkbox-disabled {
  pointer-events: none;

  .shl-checkbox-box:after {
    border-color: #b9b9b9;
  }

  .shl-checkbox-label {
    color: #b9b9b9;
  }

  .shl-checkbox-input:checked + .shl-checkbox-box:after {
    background-color: #b9b9b9;
    border-color: #b9b9b9;
  }
}

.checkbox-invalid {
  .shl-checkbox-box:after {
    -webkit-box-shadow: inset 0px 0px 0px 1px #ec0808;
    -moz-box-shadow: inset 0px 0px 0px 1px #ec0808;
    box-shadow: inset 0px 0px 0px 1px #ec0808;
  }
}

.shl-checkbox-required-symball {
  color: #ec0808;
}
